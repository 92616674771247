import React, { useEffect, useState } from 'react';
import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import Dropzone from 'react-dropzone';
import { HighlightOffRounded } from '@material-ui/icons';
import {
  DocumentTemplateType,
  IBidSetup,
  INumberedDocumentView,
} from '../../api-client/autogenerated';
import { getTemplateId } from '../../models/api/templates';
import { insertDocument } from '../../models/api/documents';
import { uploadToStaging } from '../../models/api/filesystem';
import { getUserState } from '../../features/user/selectors';
import {
  openInNewTab,
  parseDate,
  toggleState,
  waitForFileToBeVerifiedByUrl,
} from '../../scripts/utils';
import { getDocumentsByProjectIdAndType } from '../../models/api/project';
import CircularLoader from '../../main-components/loader/CircularLoader';
import PDFIcon from '../../main-components/icons/PDF-icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import BiddingTable from '../initial-bid-documents/BiddingTable';
import getRFIRows from './getRFIRows';
import { FieldWidth, gray300, gray400, gray50, gray700 } from '../BiddingPortalTheme';
import dayjs, { Dayjs } from 'dayjs';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { addSnackbar } from '../../features/snackbar/actions';

type Props = {
  bidSetup?: IBidSetup;
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '26px',
    lineHeight: '30px',
    textAlign: 'left',
    color: '#0947B9',
    marginBottom: '8px',
    paddingLeft: 8,
  },
  subtitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '18px',
    lineHeight: '21px',
    textAlign: 'left',
    textTransform: 'capitalize',
    color: '#464546',
    paddingLeft: 8,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '-16px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginRight: '7px',
    marginLeft: '7px',
  },
  rootIconButton: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  // dropzone: {
  //   flexGrow: 1,
  //   flexShrink: 0,
  //   width: '50%',
  // },
  // dropzoneText: {
  //   fontFamily: 'Roboto',
  //   fontStyle: 'normal',
  //   fontWeight: 700,
  //   fontSize: '22px',
  //   lineHeight: '40px',
  //   textTransform: 'none',
  //   paddingRight: '24px',
  //   paddingLeft: '24px',
  // },
  textfield: {
    width: '320px',
    height: '260px',
  },
  actions: {
    padding: '8px 29px 28px',
  },
  dragDropText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '40px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
  },
  browseFileButton: {
    border: '2px solid #0947B9',
    boxSizing: 'border-box',
    borderRadius: '4px',
    color: '#0947B9',
  },
  dropzoneStyling: {
    width: '320px',
    flexShrink: 0,
    background: '#F9F9F9',
    mixBlendMode: 'normal',
    border: '2px dashed #949494',
    boxSizing: 'border-box',
    borderRadius: '4px',
    paddingBottom: 48,
  },
  file: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '16px',
  },
  fileOuter: {
    display: 'inline-flex',
    marginTop: '16px',
    marginRight: '20px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fileText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '11px',
    lineHeight: '13px',
    textAlign: 'center',
    textTransform: 'none',
    color: '#949494', // Gray 400
    paddingLeft: 8,
  },
  columnLayout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  rowLayout: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  titleStyle: {
    display: 'flex',
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
    fontSize: 26,
  },
  filledInputRoot: {
    height: '34px',
    width: '216px',
    border: 'none',
    '& .Mui-disabled': {
      background: gray400,
    },
  },
  filledInputInput: {
    background: gray50,
    padding: '8px 0px 8px 8px',
    border: `1px solid ${gray400}`,
    borderRadius: '5px',
    textAlign: 'start',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '15px',
    lineHeight: '18px',
    color: gray700,
    '&::placeholder': {
      fontStyle: 'italic',
      color: gray300,
      opacity: 1,
    },
  },
  filledInputMultiline: {
    height: 'auto',
    width: 'auto',
    padding: 0,
  },
  textFieldLabel: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: '12px',
    lineHeight: '32px',
    color: gray400,
    textTransform: 'uppercase',
  },
}));

const RFIs: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { bidSetup } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUserState);

  const [addRFIVisible, setAddRFIVisible] = useState(false);
  const toggleVisible = () => toggleState(addRFIVisible, setAddRFIVisible);

  const [bidderRfis, setBidderRfis] = useState<INumberedDocumentView[]>([]);
  const [descriptionText, setDescriptionText] = useState<string>('');
  const [inputFile, setInputFile] = useState<File | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [submissionDueDate, setSubmissionDueDate] = useState<Dayjs>();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [progressText, setProgressText] = useState('');
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const handleRemove = () => {
    setInputFile(null);
  };

  const fetchDocuments = async () => {
    setIsLoading(true);
    await getDocumentsByProjectIdAndType(
      bidSetup!.project!.id!,
      DocumentTemplateType.BidderRfIs,
      1000,
      user.company?.name || user.publicCompanyName
        ? [
            {
              whereColumn: 'contactCompany',
              whereOperator: '=',
              whereValue: user.company?.name || user.publicCompanyName!,
            },
          ]
        : [],
    )
      .then((result) => setBidderRfis(result.filter((x) => !x.isHidden)))
      .catch(() => setBidderRfis([]))
      .finally(() => setIsLoading(false));
  };

  const handleSubmit = async () => {
    if (!bidSetup?.project?.id) return;
    try {
      dispatch(blockNavigation());
      setIsSubmitting(true);

      let s3Key = '';
      let verified = false;

      if (inputFile) {
        setProgressText(`Uploading ${inputFile.name}...`);
        s3Key = (
          await uploadToStaging(
            { fileName: inputFile.name, useMultiPartUpload: inputFile.size > MULTI_PART_FILE_SIZE },
            inputFile,
            handleUploadProgress,
          )
        ).s3Key;
        setProgressText('Verifying upload...');
        verified = await waitForFileToBeVerifiedByUrl(s3Key);
      }

      if (verified) {
        setProgressText('Creating document...');
        await insertDocument(
          {
            documentTemplateId: await getTemplateId(DocumentTemplateType.BidderRfIs),
            creatorUserId: user.id,
            lastUpdatedByUserId: user.id,
            projectId: bidSetup.project.id,
            contactName: user.name,
            contactCompany: user.company?.name || user.publicCompanyName,
            description: descriptionText,
          },
          { bidStagingS3Keys: s3Key ? [s3Key] : undefined },
        );

        await fetchDocuments();
        toggleVisible();
        setDescriptionText('');
        setInputFile(null);
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Successfully uploaded your request!',
            severity: 'success',
          }),
        );
      } else {
        dispatch(
          addSnackbar({
            id: Date.now(),
            message: 'Something went wrong while uploading your file. Please try again.',
            severity: 'error',
          }),
        );
      }
    } finally {
      setIsSubmitting(false);
      dispatch(allowNavigation());
    }
  };

  useEffect(() => {
    if (bidSetup?.project?.id) {
      if (bidSetup.requestDueDate) setSubmissionDueDate(parseDate(bidSetup.requestDueDate));
      fetchDocuments();
    }
  }, [bidSetup]);

  return (
    <>
      <Card style={{ height: '100%', display: 'flex', flexDirection: 'column', minHeight: 450 }}>
        <CardContent className={classes.titleStyle}>
          <h2
            className="h2"
            style={{
              textAlign: 'left',
              color: '#FFFFFF',
              margin: 0,
              lineHeight: 1,
              fontSize: 26,
            }}
          >
            Requests for Information
          </h2>
          {submissionDueDate && (
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
                lineHeight: 1,
                fontSize: 26,
                whiteSpace: 'pre',
              }}
            >
              {' '}
              — Submit by: {submissionDueDate.format('MM/DD/YYYY')}
            </h2>
          )}
        </CardContent>
        <CardContent style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {submissionDueDate && dayjs().isBefore(submissionDueDate) ? (
            <div>
              <Button
                type="button"
                disabled={
                  bidSetup?.allowElectronicRfiSubmittals === null
                    ? false
                    : !bidSetup?.allowElectronicRfiSubmittals
                }
                tabIndex={0}
                onKeyDown={toggleVisible}
                onClick={toggleVisible}
                color="primary"
                variant="contained"
                startIcon={<Add />}
                style={{
                  marginBottom: 24,
                  marginTop: 12,
                  width: 'auto',
                  paddingLeft: 16,
                  paddingRight: 16,
                }}
              >
                Submit new RFI
              </Button>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 32,
                paddingBottom: 32,

                color: 'rgb(130,130,130)',
              }}
            >
              <div>RFI submission deadline has passed.</div>
            </div>
          )}
          {(user.company?.name || user.publicCompanyName) && (
            <span style={{ textAlign: 'center' }}>
              This only shows RFIs submitted by {user.company?.name || user.publicCompanyName}
            </span>
          )}
          {isLoading ? (
            <div style={{ position: 'relative', top: '25%' }}>
              <CircularLoader />
            </div>
          ) : (
            <BiddingTable
              headers={[
                'RFI #',
                'Date Submitted',
                'Author',
                'Company',
                'Description of Request',
                'Status',
                'View',
                'Response to be Published By Addendum?',
              ]}
              rows={getRFIRows(bidderRfis, (id) => {
                if (id) openInNewTab(id, true);
              })}
              rowsPerPage={5}
            />
          )}
          <Dialog id="userDetails" open={addRFIVisible} onClose={() => setAddRFIVisible(false)}>
            <DialogTitle>
              <div className={classes.titleContainer}>
                <span className={classes.title}>Submit New RFI</span>
              </div>
              <IconButton
                style={{ right: '12px', top: '12px', position: 'absolute' }}
                onClick={() => setAddRFIVisible(false)}
                classes={{
                  root: classes.rootIconButton,
                }}
              >
                <HighlightOffRounded />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <form>
                <div className="modal-body">
                  <div
                    className="form-group required"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      paddingBottom: 16,
                    }}
                  >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <label
                        htmlFor="request"
                        className={classes.textFieldLabel}
                        style={{ paddingRight: 16 }}
                      >
                        Description
                      </label>
                      <TextField
                        id="request"
                        variant="filled"
                        multiline
                        rows={3}
                        required
                        value={descriptionText}
                        onChange={(e) => setDescriptionText(e.target.value)}
                        InputProps={{
                          style: { width: FieldWidth.Long },
                          classes: {
                            root: classes.filledInputRoot,
                            multiline: classes.filledInputMultiline,
                            input: classes.filledInputInput,
                          },
                          disableUnderline: true,
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      paddingTop: 16,
                      paddingBottom: 0,
                    }}
                  >
                    {!inputFile ? (
                      <div className={classes.dropzoneStyling}>
                        <Dropzone
                          accept={['.pdf', '.zip']}
                          onDropAccepted={(files) => {
                            setInputFile(files[0]);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div
                              style={{ outline: 'none' }}
                              {...getRootProps({ className: 'dropzone' })}
                            >
                              <input {...getInputProps()} />
                              <p className={classes.dragDropText} style={{ paddingTop: 10 }}>
                                Drag &amp; Drop files here
                              </p>
                              <p className={classes.dragDropText}>or</p>
                              <div
                                style={{
                                  display: 'flex',
                                  width: '100%',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button variant="outlined" className={classes.browseFileButton}>
                                  <Add />
                                  Browse Files
                                </Button>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    ) : (
                      <div className={classes.fileOuter}>
                        <div className={classes.file}>
                          <PDFIcon />
                          <Typography className={classes.fileText}>{inputFile.name}</Typography>
                        </div>
                        <IconButton
                          classes={{
                            root: classes.rootIconButton,
                          }}
                          onClick={() => handleRemove()}
                        >
                          <HighlightOffRounded />
                        </IconButton>
                      </div>
                    )}
                  </div>
                </div>
                {!isSubmitting ? (
                  <div className="modal-footer">
                    <div
                      style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: 12 }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        tabIndex={-1}
                        onKeyDown={toggleVisible}
                        onClick={() => {
                          handleRemove();
                          toggleVisible();
                        }}
                        style={{ background: '#F28B00', marginRight: '6px', height: 32 }}
                      >
                        Cancel
                      </Button>
                      <Button color="primary" variant="contained" onClick={handleSubmit}>
                        Submit
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      marginTop: 8,
                      marginBottom: 8,
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {progressText}
                    </Typography>
                    {progressText.includes('Uploading') ? (
                      <>
                        <Typography variant="body1" style={{ fontSize: 14, marginTop: 4 }}>
                          {uploadProgress}%
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={uploadProgress}
                          style={{ width: '100%', height: 6, marginBottom: 8, marginTop: 4 }}
                        />
                      </>
                    ) : (
                      <CircularLoader style={{ margin: '4px 0px 4px 0px' }} />
                    )}

                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      DO NOT NAVIGATE AWAY FROM THIS PAGE
                    </Typography>
                  </div>
                )}
              </form>
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
    </>
  );
};

export default RFIs;
