import React, { useEffect, useRef, useState } from 'react';
import CardContent from '@material-ui/core/CardContent';
import { Card, Grid, Hidden, Link } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import 'chartjs-plugin-doughnutlabel';
import { useSelector } from 'react-redux';
import SeeAllWhite from './SeeAllWhite';
import {
  ActionTakenType,
  DocumentPriorityType,
  DocumentTemplateType,
  SecurityPermissionLevel,
  WorkflowStatusType,
} from '../../api-client/autogenerated';
import CircularLoader from '../loader/CircularLoader';
import { documentIsOverdue, documentTypeToUrl } from '../../scripts/utils';
import { getProjectState } from '../../features/project/selectors';
import { useHistory } from 'react-router-dom';
import {
  getDocumentsSummaryByProjectIdAndType,
  IDocumentSummary,
} from '../../models/api/documents';
import AddDocumentDialog from '../dialogs/AddDocumentDialog';
import { Add } from '@material-ui/icons';
import { getCurrentSecurityGroup } from '../../features/security/selectors';
import { getTemplateId } from '../../models/api/templates';
import { getUserState } from '../../features/user/selectors';
import { hasCompletedConstructionSetup } from '../../scripts/store-utils';
import RightArrowIconWhite from '../icons/RightArrow-icon-white';

interface DashboardDoughnutsProps {
  type:
    | DocumentTemplateType.Submittals
    | DocumentTemplateType.RequestsForInformation
    | DocumentTemplateType.Task;
}

const useStyles = makeStyles({
  root: {
    top: '83px',
    flexGrow: 1,
    boxShadow: 'none',
  },
  paper: {
    position: 'relative',
    height: '100%',
    background: 'inherit',
  },
  titleStyle: {
    background: 'linear-gradient(225deg, #00308C 0%, #002366 100%)',
    borderRadius: '4px 4px 0px 0px',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
  },
  infoGrid: {
    border: 'solid 1px #EDECEC',
    minHeight: '244px',
  },
  link: {
    fontSize: 16,
    // fontStyle: 'bold',
    lineHeight: 0,
    '&:hover': {
      filter: 'brightness(0.8)',
      textDecoration: 'none',
      color: '#FFFFFF',
    },
    color: '#FFFFFF',
  },
});

const SubmittalColors = [
  '#FF9900', // Awaiting Submission
  '#4380ED', // Submitted for Review
  '#73A3FF', // Under Review
  '#A9ECBF', // Completed
  '#FFDE9F', // Revise & Resubmit
  '#FF8B7B', // Overdue
];
const RFIcolors = [
  '#4380ED', // Submitted for Review
  '#73A3FF', // Under Review
  '#A9ECBF', // Review Complete
  '#33CC33', // Resolved
  '#FFDE9F', // Resubmitted
  '#FF8B7B', // Overdue
];
const TaskColors = [
  '#FF9900', // FIO
  '#4380ED', // Not Started
  '#73A3FF', // In Progress
  '#A9ECBF', // Completed
  '#33CC33', // Accepted
  '#FFDE9F', // Resubmitted
  '#FF8B7B', // Overdue
];

export default function DashboardDoughnut(props: DashboardDoughnutsProps) {
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector(getUserState);
  const project = useSelector(getProjectState);
  const securityGroup = useSelector(getCurrentSecurityGroup);
  const { type } = props;

  const chartRef = useRef<Doughnut>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState<IDocumentSummary[]>([]);
  const [data, setData] = useState([0, 0, 0, 0, 0, 0, 0]);

  const [canCreateTasks, setCanCreateTasks] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const anchor = useRef(null);

  const hasConstructionSetupPermission =
    user.isSiteAdmin ||
    user.adminOfSubscriberId === project?.subscriberId ||
    !!securityGroup?.constructionManagementPermission;

  const fetchDocuments = async () => {
    if (!project) return;
    try {
      setIsLoading(true);
      let results: IDocumentSummary[] = [];
      if (type === DocumentTemplateType.Submittals) {
        results.push(
          ...(
            await Promise.all([
              getDocumentsSummaryByProjectIdAndType(project.id, DocumentTemplateType.Submittals),
              getDocumentsSummaryByProjectIdAndType(
                project.id,
                DocumentTemplateType.SubmittalPackages,
              ),
            ])
          ).flat(),
        );
      } else {
        results.push(...(await getDocumentsSummaryByProjectIdAndType(project.id, type)));
      }
      setDocuments(results);
    } catch {
      setDocuments([]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, [project, type]);

  useEffect(() => {
    if (type === DocumentTemplateType.Task && project) {
      getTemplateId(DocumentTemplateType.Task).then((taskTemplateId) => {
        const taskPermission = securityGroup?.securityGroupDocumentTemplateList?.find(
          (sgdt) => sgdt.documentTemplateId === taskTemplateId,
        )?.securityPermissionLevel;
        setCanCreateTasks(
          user.isSiteAdmin ||
            user.adminOfSubscriberId === project.subscriberId ||
            !!(taskPermission && taskPermission >= SecurityPermissionLevel.NUMBER_3),
        );
      });
    }
  }, [securityGroup, type, project, user]);

  useEffect(() => {
    const tempData = [0, 0, 0, 0, 0, 0, 0];
    documents.forEach((document) => {
      if (type === DocumentTemplateType.RequestsForInformation) {
        switch (document.workflowStatus) {
          case WorkflowStatusType.SubmittedForReview:
            if (documentIsOverdue(document)) tempData[5] += 1;
            tempData[0] += 1;
            break;
          case WorkflowStatusType.ArchitectUploaded:
          case WorkflowStatusType.UnderReview:
            if (documentIsOverdue(document)) tempData[5] += 1;
            tempData[1] += 1;
            break;
          case WorkflowStatusType.ReviewComplete:
            tempData[2] += 1;
            break;
          case WorkflowStatusType.Resolved:
            tempData[3] += 1;
            break;
          case WorkflowStatusType.Resubmitted:
            tempData[4] += 1;
            break;
        }
      } else if (type === DocumentTemplateType.Submittals) {
        if (document.submittalPackageDocumentId) return;
        if (document.actionTaken === ActionTakenType.ReviseAndResubmit) tempData[4] += 1;
        switch (document.workflowStatus) {
          case WorkflowStatusType.Initial:
          case WorkflowStatusType.SubcontractorUploaded:
          case WorkflowStatusType.ReadyForSubmissionToArchitect:
          case WorkflowStatusType.GeneralContractorUploaded:
            if (documentIsOverdue(document, true)) tempData[5] += 1;
            tempData[0] += 1;
            break;
          case WorkflowStatusType.SubmittedForReview:
            if (documentIsOverdue(document, true)) tempData[5] += 1;
            tempData[1] += 1;
            break;
          case WorkflowStatusType.UnderReview:
          case WorkflowStatusType.ArchitectUploaded:
            if (documentIsOverdue(document, true)) tempData[5] += 1;
            tempData[2] += 1;
            break;
          case WorkflowStatusType.Completed:
            tempData[3] += 1;
            break;
        }
      } else {
        if (document.priority === DocumentPriorityType.ForInformationOnly) {
          tempData[0] += 1;
          return;
        }
        switch (document.workflowStatus) {
          case WorkflowStatusType.Initial:
          case WorkflowStatusType.SubcontractorUploaded:
          case WorkflowStatusType.ReadyForSubmissionToArchitect:
          case WorkflowStatusType.GeneralContractorUploaded:
          case WorkflowStatusType.SubmittedForReview:
            if (documentIsOverdue(document)) tempData[6] += 1;
            tempData[1] += 1;
            break;
          case WorkflowStatusType.UnderReview:
          case WorkflowStatusType.ArchitectUploaded:
            if (documentIsOverdue(document)) tempData[6] += 1;
            tempData[2] += 1;
            break;
          case WorkflowStatusType.ReviewComplete:
            tempData[3] += 1;
            break;
          case WorkflowStatusType.Resolved:
            tempData[4] += 1;
            break;
          case WorkflowStatusType.Resubmitted:
            tempData[5] += 1;
            break;
        }
      }
    });
    setData(tempData);
  }, [documents]);

  const getLabels = () => {
    switch (type) {
      case DocumentTemplateType.Submittals:
        return getSubmittalLabels();
      case DocumentTemplateType.RequestsForInformation:
        return getRfiLabels();
      case DocumentTemplateType.Task:
        return getTaskLabels();
    }
  };

  const getSubmittalLabels = () => [
    `Awaiting Submission ${data[0]}`,
    `Submitted for Review ${data[1]}`,
    `Under Review ${data[2]}`,
    `Completed ${data[3]}`,
    `Revise & Resubmit ${data[4]}`,
    `Overdue ${data[5]}`,
  ];

  const getRfiLabels = () => [
    `Submitted for Review ${data[0]}`,
    `Under Review ${data[1]}`,
    `Review Complete ${data[2]}`,
    `Resolved ${data[3]}`,
    `Resubmitted ${data[4]}`,
    `Overdue ${data[5]}`,
  ];

  const getTaskLabels = () => [
    `For Information Only ${data[0]}`,
    `Not Started ${data[1]}`,
    `In Progress ${data[2]}`,
    `Completed ${data[3]}`,
    `Accepted ${data[4]}`,
    `Resubmitted ${data[5]}`,
    `Overdue ${data[6]}`,
  ];

  const getColors = () => {
    switch (type) {
      case DocumentTemplateType.Submittals:
        return SubmittalColors;
      case DocumentTemplateType.RequestsForInformation:
        return RFIcolors;
      case DocumentTemplateType.Task:
        return TaskColors;
    }
  };

  const getDocumentCount = () => {
    if (type === DocumentTemplateType.Submittals) {
      return documents.filter((d) => !d.submittalPackageDocumentId).length;
    } else {
      return documents.length;
    }
  };

  const pieData = {
    labels: getLabels(),
    datasets: [
      {
        data: type !== DocumentTemplateType.Task ? data.slice(0, 6) : data,
        backgroundColor: getColors(),
        // hoverBackgroundColor: ['#C4C4C4', '#C4C4C4', '#C4C4C4'],
      },
    ],
    // text: `${documents.length} Total`,
  };

  const emptyData = {
    labels: getLabels(),
    datasets: [
      {
        data: [1, 0, 0, 0, 0, 0, 0],
        backgroundColor: ['#EDECEC'],
      },
    ],
  };

  const plugins = {
    doughnutlabel: {
      labels: [
        {
          text: getDocumentCount(),
          font: {
            size: 44,
            lineHeight: '52px',
          },
        },
        {
          text: 'total',
          font: {
            size: 26,
            lineHeight: '30px',
          },
        },
      ],
    },
  };

  const doughnutOptions =
    documents.length === 0
      ? {
          animation: { duration: 0 },
          maintainAspectRatio: false,
          responsive: true,
          plugins,
          events: [],
        }
      : {
          animation: { duration: 0 },
          maintainAspectRatio: false,
          responsive: true,
          plugins,
          tooltips: {
            callbacks: {
              label: (tooltipItem: { index: number }, data: { labels: string[] }) => {
                return data.labels[tooltipItem.index];
              },
            },
          },
          hover: {
            onHover: (e: any, other: any) => {
              e.target.style.cursor = other.length ? 'pointer' : 'default';
            },
          },
          onClick: (e: any, items: any[]) => {
            if (items.length > 0) {
              setTimeout(() => {
                chartRef.current?.chartInstance.destroy();
                history.push(`/main/projects/${project!.id}/documents/${documentTypeToUrl[type]}`, {
                  status: getStatusFromLabel(items[0]._view.label),
                });
              }, 100);
            }
          },
        };

  const legendOptions = {
    onClick: (event: MouseEvent, item: { text: string }) => {
      history.push(`/main/projects/${project!.id}/documents/${documentTypeToUrl[type]}`, {
        status: getStatusFromLabel(item.text),
      });
    },
    onHover: (e: any) => {
      e.target.style.cursor = 'pointer';
    },
  };

  const getStatusFromLabel = (label: string): string => {
    switch (label.slice(0, label.lastIndexOf(' '))) {
      default:
      case 'Awaiting Submission':
        return WorkflowStatusType.Initial;

      case 'Submitted for Review':
      case 'Not Started':
        return WorkflowStatusType.SubmittedForReview;

      case 'Under Review':
      case 'In Progress':
        return WorkflowStatusType.UnderReview;

      case 'Resolved':
      case 'Accepted':
        return WorkflowStatusType.Resolved;

      case 'Resubmitted':
        return WorkflowStatusType.Resubmitted;

      case 'Review Complete':
        return WorkflowStatusType.ReviewComplete;

      case 'Completed':
        if (type === DocumentTemplateType.Task) return WorkflowStatusType.ReviewComplete;
        return WorkflowStatusType.Completed;

      case 'Revise & Resubmit':
        return ActionTakenType.ReviseAndResubmit;

      case 'Overdue':
        return 'overdue';

      case 'For Information Only':
        return DocumentPriorityType.ForInformationOnly;
    }
  };

  const getTitle = () => {
    switch (type) {
      case DocumentTemplateType.Submittals:
        return 'Submittals';
      case DocumentTemplateType.RequestsForInformation:
        return 'RFIs';
      case DocumentTemplateType.Task:
        return 'Design - Task & Share';
    }
  };

  const handleConstructionSetupLink = () => {
    history.push(`/main/projects/${project?.id}/const-mgmt`);
  };

  const getButton = () => {
    if (type === DocumentTemplateType.Task || hasCompletedConstructionSetup())
      return (
        <SeeAllWhite link={`/main/projects/${project!.id}/documents/${documentTypeToUrl[type]}`} />
      );

    if (hasConstructionSetupPermission)
      return (
        <Link onClick={handleConstructionSetupLink} className={classes.link}>
          Construction Setup <RightArrowIconWhite />
        </Link>
      );

    return null;
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.titleStyle}>
        <Grid container justify="space-between" alignContent="center">
          <Grid item>
            <h2
              className="h2"
              style={{
                textAlign: 'left',
                color: '#FFFFFF',
                margin: 0,
              }}
            >
              {getTitle()}
            </h2>
          </Grid>

          <Grid item>
            <Button style={{ padding: '0px' }}>{getButton()}</Button>
          </Grid>
        </Grid>
      </CardContent>

      <CardContent
        className={classes.flexColumn}
        style={{ paddingLeft: '0px', paddingRight: '0px', paddingBottom: '0px' }}
      >
        {isLoading ? (
          <div
            style={{
              position: 'relative',
              top: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 50,
            }}
          >
            <CircularLoader />
          </div>
        ) : (
          <Grid container justify="center" spacing={1} alignItems="stretch">
            <div style={{ width: '100%' }}>
              {canCreateTasks && (
                <Button
                  ref={anchor}
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => setCreateDialogOpen(true)}
                  style={{ marginLeft: 32 }}
                >
                  Create
                </Button>
              )}
            </div>
            <Grid item xs={12} style={{ padding: '16px' }}>
              <Paper className={classes.paper}>
                <Hidden smDown>
                  <div style={{ width: '99%' }}>
                    <Doughnut
                      ref={chartRef}
                      data={documents.length === 0 ? emptyData : pieData}
                      width={300}
                      height={300}
                      legend={{ position: 'right', ...legendOptions }}
                      options={doughnutOptions}
                    />
                  </div>
                </Hidden>
                <Hidden mdUp>
                  <div style={{ width: '99%' }}>
                    <Doughnut
                      ref={chartRef}
                      data={documents.length === 0 ? emptyData : pieData}
                      width={300}
                      height={300}
                      legend={{ position: 'bottom', ...legendOptions }}
                      options={doughnutOptions}
                    />
                  </div>
                </Hidden>
              </Paper>
            </Grid>
          </Grid>
        )}
      </CardContent>
      {canCreateTasks && (
        <AddDocumentDialog
          dialogOpen={createDialogOpen}
          handleClose={() => setCreateDialogOpen(false)}
          anchor={anchor.current}
          isDashboard
        />
      )}
    </Card>
  );
}
