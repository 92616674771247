import _ from 'lodash';
import {
  ActionTakenType,
  AdditionalReviewRowProps,
  DocumentTemplateType,
  FileNode,
  IDocument,
  IInsertionComment,
  IInsertionDocumentWithGraph,
  IModifyDocument,
  InsertDocumentProps,
  INumberedDocumentView,
  NotificationSettingType,
  RespondToReviewProps,
  SubmittalReviewProps,
  SubmittalReviewRowProps,
  UpdateReviewParams,
} from '../../api-client/autogenerated';
import { ApiClient, getQueryFilterString, QueryFilter } from '../../api-client/api-client';
import { ImportFromDesignRequest, uploadS3File } from './filesystem';
import { getTemplateId } from './templates';
import { isPublicPage } from '../../scripts/utils';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';

// GET //

export const getDocuments = async (limit = 50): Promise<IDocument[]> => {
  const response = await ApiClient.getAllDocuments({ pageSize: limit });
  return response.data.results;
};

export const getDocumentById = async (documentId: string): Promise<INumberedDocumentView> => {
  const response = await ApiClient.getDocumentById({ id: documentId });
  return response.data;
};

export const getSoonestDueDocumentsAssignedToUser = async (
  userId: string,
  projectId?: string,
): Promise<INumberedDocumentView[]> => {
  const filter: QueryFilter = [];
  if (projectId)
    filter.push({
      whereColumn: 'numbered_document_view.projectId',
      whereOperator: '=',
      whereValue: projectId,
    });
  const response = await ApiClient.getDocumentsAssignedToMe({
    pageSize: 1000,
    orderByColumn: 'dueDate',
    orderByDirection: 'asc',
    filterByObject: getQueryFilterString(filter),
  });
  return response.data.results;
};

export const getSoonestDueDocumentsAssignedByUser = async (
  userId: string,
  projectId?: string,
): Promise<INumberedDocumentView[]> => {
  const filter: QueryFilter = [];
  if (projectId)
    filter.push({
      whereColumn: 'numbered_document_view.projectId',
      whereOperator: '=',
      whereValue: projectId,
    });
  const response = await ApiClient.getDocumentsAssignedByMe({
    pageSize: 1000,
    orderByColumn: 'dueDate',
    orderByDirection: 'asc',
    filterByObject: getQueryFilterString(filter),
  });
  return response.data.results;
};

export const getSoonestDueDocuments = async (
  userId: string,
  projectId?: string,
): Promise<INumberedDocumentView[]> => {
  const assignedToUser = await getSoonestDueDocumentsAssignedToUser(userId, projectId);
  const assignedByUser = await getSoonestDueDocumentsAssignedByUser(userId, projectId);
  return _.concat(assignedToUser, assignedByUser);
};

export const getDocumentUserPermissionByDocumentId = (documentId: string) => {
  return ApiClient.getDocumentUserPermissionByDocumentId({ documentId }).then((res) => res.data);
};

export const insertDocument = async (
  doc: IInsertionDocumentWithGraph,
  options: Omit<InsertDocumentProps, 'document'> = {},
): Promise<INumberedDocumentView> => {
  const response = await ApiClient.insertDocument({
    insertDocumentProps: {
      document: doc,
      ...options,
    },
  });
  return response.data.document;
};

export const insertDocumentWithType = async (
  doc: Omit<IInsertionDocumentWithGraph, 'documentTemplateId'>,
  docType: DocumentTemplateType,
  options: Omit<InsertDocumentProps, 'document'> = {},
) => {
  const documentTemplateId = await getTemplateId(docType);
  return insertDocument({ ...doc, documentTemplateId }, options);
};

export const generateAsBuiltDocument = async (
  documentId: string,
  followerUserIds: string[] = [],
  userGroupIds: string[] = [],
  associatedDocumentIds: string[] = [],
) => {
  return ApiClient.generateAsBuiltDocumentFromDrawingsDocumentId({
    documentId,
    generateAsBuiltDocumentParams: { followerUserIds, userGroupIds, associatedDocumentIds },
  }).then((res) => res.data.document);
};

export const modifyDocumentByIdWithResponse = async (
  documentId: string,
  modification: IModifyDocument,
) => {
  return ApiClient.modifyDocumentByIdWithResponse({
    documentId,
    iModifyDocumentRequest: modification,
  }).then((res) => res.data);
};

export const modifyDocumentById = async (documentId: string, modification: IModifyDocument) => {
  return ApiClient.modifyDocumentById({ documentId, iModifyDocumentRequest: modification }).then(
    (res) => res.data,
  );
};

export type ModifyDocumentRow = {
  documentId: string;
  modification: IModifyDocument;
};

export const modifyDocumentsIndividually = async (
  modifications: ModifyDocumentRow[],
  allowReplaceExisting = true,
) => {
  return ApiClient.modifyDocumentsByBatches({
    iModifyDocumentsByBatchesRequest: {
      allowReplaceExisting,
      batches: modifications.map((m) => ({
        documentIds: [m.documentId],
        modificationRequest: m.modification,
      })),
    },
  }).then((res) => res.data);
};

export const modifyDocumentsByBatch = async (
  documentIds: string[],
  modification: IModifyDocument,
  allowReplaceExisting = false,
  fileUploads?: {
    submittalSectionFile?: File | FileNode;
    onBeginSubmittalSectionUpload?: (file: File | FileNode) => void;
    files?: (File | FileNode)[];
    onBeginFileUpload?: (file: File | FileNode) => void;
    onUploadProgress?: (progressEvent: any) => void;
  },
) => {
  const { data } = await ApiClient.modifyDocumentsByBatch({
    iModifyDocumentsByBatchRequest: {
      documentIds,
      allowReplaceExisting,
      modificationRequest: modification,
    },
  });
  for (const response of data.modificationResponses) {
    for (const [uploadResponse, file] of _.zip(
      response.modificationResponse.fileUploadResponses,
      fileUploads?.files ?? [],
    )) {
      if (!file) continue;
      if (!uploadResponse) continue;
      fileUploads?.onBeginFileUpload?.(file);
      if (file instanceof File)
        await uploadS3File(uploadResponse, file, fileUploads?.onUploadProgress);
      else if (fileUploads?.onUploadProgress)
        fileUploads?.onUploadProgress({ loaded: 1, total: 1 });
    }
    if (
      fileUploads?.submittalSectionFile &&
      fileUploads?.submittalSectionFile instanceof File &&
      response.modificationResponse.submittalSectionUploadResponse
    ) {
      await fileUploads?.onBeginSubmittalSectionUpload?.(fileUploads.submittalSectionFile!);
      await uploadS3File(
        response.modificationResponse.submittalSectionUploadResponse,
        fileUploads?.submittalSectionFile,
        fileUploads?.onUploadProgress,
      );
    }
  }
};

/**
 * Can be used to "delete" a document as an admin, or immediately after creating a document.
 * @param {string} documentId The id of the document to delete
 */
export const deleteDocumentById = async (documentId: string) => {
  return ApiClient.deleteDocumentById({ documentId });
};

export const deleteDocumentsByIds = async (documentIds: string[]) => {
  return ApiClient.deleteDocumentsByIds({ deleteDocumentsRequest: { documentIds } });
};

export const restoreDocumentById = async (documentId: string) => {
  return ApiClient.restoreDocumentById({ documentId });
};

export const restoreDocumentsByIds = async (documentIds: string[]) => {
  return ApiClient.restoreDocumentsByIds({ restoreDocumentsRequest: { documentIds } });
};

export const withdrawDocumentById = async (documentId: string) => {
  return ApiClient.withdrawDocumentById({ documentId });
};

export const removeDocumentFromPackageByDocumentId = async (documentId: string) => {
  const response = await ApiClient.removeDocumentFromPackageByDocumentId({ documentId });
  return response.data;
};

export const editSubcontractorByDocumentId = (
  documentId: string,
  dueDate: string,
  subcontractorEmail: string,
) => {
  return ApiClient.editSubcontractorOfDocumentById({
    documentId,
    subcontractorChangeRequest: { subcontractorEmail, dueDate },
  }).then((res) => res.data);
};

export const insertSubmissionByDocumentId = async (
  documentId: string,
  fullFileName: string,
  file: File,
  comment?: IInsertionComment,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.insertSubmissionByDocumentId({
    documentId,
    submittalUploadRequest: {
      fullFileName,
      comment,
      useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
    },
  });

  await uploadS3File(response.data, file, onUploadProgress);

  return response.data;
};

export const importSubmissionFromDesignByDocumentId = async (
  documentId: string,
  params: ImportFromDesignRequest,
  comment?: IInsertionComment,
) => {
  return ApiClient.insertSubmissionByDocumentId({
    documentId,
    submittalUploadRequest: { ...params, comment, useMultiPartUpload: false },
  }).then((res) => res.data);
};

export const acceptSubmissionByDocumentId = (documentId: string) => {
  return ApiClient.acceptSubmissionByDocumentId({ documentId }).then((res) => res.data);
};

export const refuseAssignmentByDocumentId = (documentId: string) => {
  return ApiClient.subcontractorRefuseAssignmentByDocumentId({ documentId }).then(
    (res) => res.data,
  );
};

export const cancelSubmissionByDocumentId = (documentId: string) => {
  return ApiClient.notReadyForSubmissionByDocumentId({ documentId }).then((res) => res.data);
};

export const refuseSubmissionByDocumentId = (documentId: string, comment?: IInsertionComment) => {
  return ApiClient.refuseSubmissionByDocumentId({
    documentId,
    rejectDocumentRequest: { comment },
  }).then((res) => res.data);
};

export const skipAssignSubcontractorByDocumentId = (documentId: string) => {
  return ApiClient.skipAssignSubcontractorByDocumentId({ documentId }).then((res) => res.data);
};

export const submitToArchitectByDocumentId = (documentId: string, architectUserId?: string) => {
  return ApiClient.submitToArchitectByDocumentId({
    documentId,
    submitToArchitectProps: { architectUserId },
  }).then((res) => res.data);
};

export const completeReviewByDocumentId = async (
  documentId: string,
  options: Omit<SubmittalReviewProps, 'packageReview'> = {},
) => {
  return ApiClient.completeReviewByDocumentId({ documentId, submittalReviewProps: options }).then(
    (res) => res.data,
  );
};

export const completeReviewForPackageByDocumentId = async (
  documentId: string,
  packageReview?: SubmittalReviewRowProps[],
  copiedCommentIds?: string[],
) => {
  return ApiClient.completeReviewByDocumentId({
    documentId,
    submittalReviewProps: { packageReview, copiedCommentIds },
  }).then((res) => res.data);
};

export const undoCompleteReviewByDocumentId = async (documentId: string) => {
  return ApiClient.undoCompleteReviewByDocumentId({ documentId }).then((res) => res.data);
};

export const updateReviewByDocumentId = async (documentId: string, params: UpdateReviewParams) => {
  return ApiClient.updateReviewByDocumentId({ documentId, updateReviewParams: params }).then(
    (res) => res.data,
  );
};

export const submitAdditionalReviewByDocumentId = async (
  documentId: string,
  recommendedActionTaken?: ActionTakenType,
  comment?: string,
  isDraft?: boolean,
) => {
  return ApiClient.submitAdditionalReviewByDocumentId({
    documentId,
    additionalReviewProps: { recommendedActionTaken, comment, isDraft },
  }).then((res) => res.data);
};

export const submitAdditionalReviewForPackageByDocumentId = async (
  documentId: string,
  packageAdditionalReview: AdditionalReviewRowProps[],
  isDraft?: boolean,
) => {
  return ApiClient.submitAdditionalReviewByDocumentId({
    documentId,
    additionalReviewProps: { packageAdditionalReview, isDraft },
  }).then((res) => res.data);
};

export const insertAdditionalReviewByDocumentId = async (
  documentId: string,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.insertAdditionalReviewByDocumentId({
    documentId,
    fileUploadRequestParams: {
      fullFileName: file.name,
      useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE,
    },
  });
  await uploadS3File(response.data, file, onUploadProgress);
  return response.data;
};

export const importAdditionalReviewFromDesignByDocumentId = async (
  documentId: string,
  params: ImportFromDesignRequest,
) => {
  return ApiClient.insertAdditionalReviewByDocumentId({
    documentId,
    fileUploadRequestParams: { ...params, useMultiPartUpload: false },
  });
};

export const respondToReview = (documentId: string, respondToReviewProps: RespondToReviewProps) => {
  return ApiClient.respondToReviewByDocumentId({
    documentId,
    respondToReviewProps,
  }).then((res) => res.data);
};

export const editDocumentSettings = (
  userId: string,
  documentId: string,
  notificationSetting: NotificationSettingType,
) => {
  return ApiClient.editDocumentSettingsByUserIdAndDocumentId({
    userId,
    documentId,
    iEditDocumentSettings: { notificationSetting },
  });
};

export const getPermissionByUserIdAndDocumentId = async (userId: string, documentId: string) => {
  const res = await ApiClient.getPermissionByUserIdAndDocumentId({ userId, documentId });
  return res.data;
};

export const getMostRecentConsultantNotificationsByDocumentIds = async (
  reviewDocumentIds: string[],
) => {
  const unauthenticatedUserEmail = isPublicPage()
    ? localStorage.getItem('publicEmail') || undefined
    : undefined;
  return ApiClient.getMostRecentConsultantNotificationsByDocumentIds({
    consultantNotificationRequest: { reviewDocumentIds },
    unauthenticatedUserEmail,
  }).then((res) => res.data);
};

export const getDocumentByKey = async (key: string) => {
  const res = await ApiClient.getDocumentByKey({ key });
  return res.data;
};

export interface IDocumentSummary
  extends Pick<
    INumberedDocumentView,
    | 'id'
    | 'actionTaken'
    | 'workflowStatus'
    | 'revisionNumber'
    | 'dueDate'
    | 'priority'
    | 'submittalPackageDocumentId'
    | 'anticipatedInitialSubmissionDate'
  > {}

export const getDocumentsSummaryByProjectIdAndType = async (
  projectId: string,
  type: DocumentTemplateType,
): Promise<IDocumentSummary[]> => {
  const documentTemplateId = await getTemplateId(type);
  const summaryDocuments: INumberedDocumentView[] = [];

  // up to 5k documents
  for (let page = 1; page <= 5; page++) {
    const documentsResponse = await ApiClient.getDocumentsSummaryByProjectIdAndDocumentTemplateId({
      filterByObject: getQueryFilterString([
        { whereColumn: 'isDraft', whereValue: false, whereOperator: '=' },
      ]),
      projectId,
      documentTemplateId,
      pageSize: 1000,
    });
    summaryDocuments.push(...documentsResponse.data.results);
    if (page >= documentsResponse.data.totalPages) break;
  }

  return summaryDocuments;
};

export const disableRemindersByDocumentKey = async (
  key: string,
  disableReminders?: boolean,
  disableNotifications?: boolean,
) => {
  return ApiClient.toggleRemindersByDocumentKey({
    toggleRemindersProps: { key, disableReminders, disableNotifications },
  }).then((res) => res.data);
};

export const getSubmittalsBySubmittalSection = async (
  projectId: string,
  submittalSection: string,
): Promise<INumberedDocumentView[]> => {
  const documentTemplateId = await getTemplateId(DocumentTemplateType.Submittals);
  const filter: QueryFilter = [
    {
      whereColumn: 'submittalSection',
      whereOperator: '=',
      whereValue: submittalSection,
    },
  ];
  const response = await ApiClient.getDocumentsByProjectIdAndDocumentTemplateId({
    projectId,
    documentTemplateId,
    pageSize: 1000,
    filterByObject: getQueryFilterString(filter),
  });
  return response.data.results;
};

export const uploadSubmittalSectionFileByDocumentId = async (
  documentId: string,
  fullFileName: string,
  file: File,
  onUploadProgress?: (progressEvent: any) => void,
) => {
  const response = await ApiClient.getUploadLinkForSubmittalSectionByDocumentId({
    documentId,
    fileUploadRequestParams: { fullFileName, useMultiPartUpload: file.size > MULTI_PART_FILE_SIZE },
  });

  await uploadS3File(response.data, file, onUploadProgress);

  return response.data;
};

export const addDocumentToPackage = async (documentId: string, packageDocumentId: string) => {
  return ApiClient.addDocumentToPackageByDocumentId({
    documentId,
    addDocumentToPackageProps: { packageDocumentId },
  }).then((res) => res.data);
};

export const InsertConsultantConfidentialCommentByDocumentId = async (
  documentId: string,
  comment: IInsertionComment,
) => {
  return ApiClient.insertConsultantConfidentialCommentByDocumentId({
    parentDocumentId: documentId,
    iInsertionComment: comment,
  }).then((res) => res.data);
};

export const sendDocumentPublicLinkEmails = async (documentId: string, emails: string[]) => {
  return ApiClient.sendDocumentPublicLinkEmails({ documentId, emailRequest: { emails } }).then(
    (res) => res.data,
  );
};

export const generateAsBuiltDocuments = async (
  projectId: string,
  excludedDocumentIds: string[],
) => {
  return ApiClient.generateAsBuiltDocumentsByProjectId({
    projectId,
    generateAsBuiltDocumentsRequest: { excludedDocumentIds },
  }).then((res) => res.data);
};

export const createLinkedDocumentsForAssignedUsers = async (
  documentId: string,
  userIds: string[],
) => {
  return ApiClient.createLinkedDocumentsForAssignedUsers({
    documentId,
    createLinkedDocumentsForAssignedUsersRequest: { userIds },
  }).then((res) => res.data);
};

export const getAssociatedDocumentsByDocumentId = async (documentId: string) => {
  return ApiClient.getAssociatedDocumentsByDocumentId({ documentId }).then((res) => res.data);
};
