import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FileWithPath, useDropzone } from 'react-dropzone';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  makeStyles,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Dayjs } from 'dayjs';
import DayjsUtils from '@date-io/dayjs';
import ClearIcon from '@material-ui/icons/ClearRounded';
import ImageIcon from '@material-ui/icons/Image';
import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import {
  DocumentTemplateType,
  FileCategoryType,
  IFile,
  IInsertionDocumentWithGraph,
  INumberedDocumentView,
  IRestrictedDocumentPatch,
} from '../../api-client/autogenerated';
import { getDocumentById, insertDocument, modifyDocumentById } from '../../models/api/documents';
import { getTemplates } from '../../models/api/templates';
import { getUserState } from '../../features/user/selectors';
import { fetchDocument, reloadDocument, setDocument } from '../../features/document/actions';
import {
  DocumentMatchParams,
  FileDataUrl,
  fileIsPdf,
  getImageByFileId,
  isSurface,
  parseDate,
  readFileAsync,
  removeNewlines,
  resizeImageHelper,
} from '../../scripts/utils';
import { deleteFile, updateFile, uploadGeneralDocumentFile } from '../../models/api/filesystem';
import NavAppbar from '../nav-top/NavAppbar';
import PageTitle from '../page-title/PageTitle';
import CircularLoader from '../loader/CircularLoader';
import ManagePermissionsDialog, {
  ManagePermissionsDialogType,
} from '../design/ManagePermissionsDialog';
import InlineNotificationList from './user-groups/InlineNotificationList';
import FileUploadDialog from '../dialogs/FileUploadDialog';
import PDFIcon from '../icons/PDF-icon';
import { CameraAlt, HighlightOff, InsertDriveFileOutlined } from '@material-ui/icons';
import { getDocPermission } from '../../scripts/store-utils';
import { allowNavigation, blockNavigation } from '../../features/navigation/actions';
import { MULTI_PART_FILE_SIZE } from '../../scripts/constants';
import { SubmitButton } from '../custom-components/CustomButtons';
import SelectCompanyUsersDialog from '../dialogs/SelectCompanyUsersDialog';
import { getProjectUsersState } from '../../features/project/selectors';
import _ from 'lodash';
import Webcam from 'react-webcam';
import TakePhotoDialog from '../dialogs/TakePhotoDialog';
import { isDesktop } from 'react-device-detect';
import { v4 } from 'uuid';

const AddFieldReportsPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<DocumentMatchParams>();
  const user = useSelector(getUserState);
  const projectUsers = useSelector(getProjectUsersState);

  const [currentDocument, setCurrentDocument] = useState<INumberedDocumentView | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [submittingMessage, setSubmittingMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [snackOpen, setSnackOpen] = useState(false);

  // Form states
  const [dateOfObservation, setDateOfObservation] = useState<Dayjs | null>(null);
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [company, setCompany] = useState<string | undefined>(user.company!.name);
  const [location, setLocation] = useState<string>();
  const [weather, setWeather] = useState<string>();
  const [partiesPresent, setPartiesPresent] = useState<string[]>([user.name]);
  const [workInProgress, setWorkInProgress] = useState<string>();
  const [associationDialogOpen, setAssociationDialogOpen] = useState(false);
  const [associatedUsers, setAssociatedUsers] = useState<string[]>([]);
  const [associatedGroups, setAssociatedGroups] = useState<string[]>([]);

  // Form image states
  const [images, setImages] = useState<FileDataUrl[]>([]);
  const [imageDescriptions, setImageDescriptions] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);
  const [isUploadingImages, setIsUploadingImages] = useState<boolean>(false);
  const [isLoadingImages, setIsLoadingImages] = useState<boolean>(false);

  // Select documents states
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false);
  const [existingFiles, setExistingFiles] = useState<IFile[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const [photoDialogOpen, setPhotoDialogOpen] = useState(false);

  const webcamRef = useRef<Webcam | null>(null);
  const captureImage = useCallback(async () => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        try {
          // window.open(imageSrc, '_blank', 'noopener,noreferrer');
          const response = await fetch(imageSrc);
          const file = new File([await response.arrayBuffer()], `${v4()}.png`, {
            type: 'image/png',
          });
          setPhotoDialogOpen(false);
          await onDrop([file]);
        } catch (e) {
          console.log(e);
        }
      } else {
        console.log('imagesrc is empty');
      }
    } else {
      console.log('webcam ref is empty');
    }
  }, [webcamRef, currentDocument, images, uploadedFiles, imageDescriptions]);

  const [partiesPresentDialogOpen, setPartiesPresentDialogOpen] = useState(false);

  const canDeleteFile = getDocPermission() === 4;

  function handleFileDelete(currFile: File) {
    const items = [...files];
    items.splice(files.indexOf(currFile), 1);
    setFiles([...items]);
  }

  const uploadFile = async (docId: string, fileToUpload: File, fileDescription?: string) => {
    if (!fileToUpload) return;
    return uploadGeneralDocumentFile(
      docId,
      {
        fullFileName: fileToUpload.name,
        fileType: FileCategoryType.DocumentAttachments,
        description: fileDescription,
        useMultiPartUpload: fileToUpload.size > MULTI_PART_FILE_SIZE,
        skipThumbnailGeneration: true,
      },
      fileToUpload,
      handleUploadProgress,
    );
  };

  const deleteUploadedImageFile = async (i: number) => {
    if (!currentDocument) return;

    const files = [...images];
    const filesDescriptions = [...imageDescriptions];
    const uploaded = [...uploadedFiles];
    files.splice(i, 1);
    filesDescriptions.splice(i, 1);
    uploaded.splice(i, 1);
    setImages([...files]);
    setImageDescriptions([...filesDescriptions]);
    setUploadedFiles([...uploaded]);
    await deleteFile(currentDocument.id || '', uploadedFiles[i].id);
    dispatch(fetchDocument(currentDocument.id)); // Update document for returning to details page without clicking save
  };

  const deleteExistingDocumentFile = async (file: IFile) => {
    if (!currentDocument) return;
    setExistingFiles((prev) => prev.filter((f) => f.id !== file.id));
    await deleteFile(currentDocument.id, file.id);
    dispatch(fetchDocument(currentDocument.id));
  };

  const onDrop = useCallback(
    async (acceptedFiles: (FileWithPath | File)[]) => {
      if (!currentDocument) return;

      try {
        setIsUploadingImages(true);

        dispatch(blockNavigation());
        const resizedImages = await Promise.all(
          acceptedFiles.map((acceptedFile) => resizeImageHelper(acceptedFile)),
        );
        const results = await Promise.all(
          resizedImages.map((image) => uploadFile(currentDocument.id, image)),
        );
        dispatch(allowNavigation());
        const newUploadedFiles = results.map((r) => r?.file!);
        setUploadedFiles([...newUploadedFiles, ...uploadedFiles]);

        const acceptedFilesDataUrl: FileDataUrl[] = [];

        for (const file of resizedImages) {
          const dataUrl = await readFileAsync(file);
          acceptedFilesDataUrl.push(dataUrl);
        }

        setImages([...acceptedFilesDataUrl, ...images]);
        setImageDescriptions([...resizedImages.map(() => ''), ...imageDescriptions]);
      } catch (error: any) {
        console.error(`Error uploading files: ${error.message}`);
        setSnackOpen(true);
      } finally {
        setIsUploadingImages(false);
        dispatch(allowNavigation());
      }
    },
    [images, imageDescriptions, uploadedFiles, currentDocument?.id],
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg, image/png',
    multiple: true,
    onDrop,
  });

  const addPartiesPresent = (userIds: string[], names: string[]) => {
    const newParties = userIds
      .map((id) => projectUsers.find((pU) => pU.userId === id)?.user?.name)
      .filter((x) => !!x) as string[];

    setPartiesPresent((prev) => _.uniq([...prev, ...newParties, ...names]));
  };

  const removeParty = (i: number) => {
    const parties = [...partiesPresent];
    parties.splice(i, 1);
    setPartiesPresent([...parties]);
  };

  const fetchCreatedDocument = async () => {
    setIsLoading(true);
    const { documentId } = params;

    const document = await getDocumentById(documentId);

    setCurrentDocument(document);
    dispatch(setDocument(document));

    setDateOfObservation(parseDate(document.dateOfObservation || ''));
    setTitle(document.title || undefined);
    setDescription(document.description || undefined);
    setCompany(document.contactCompany || undefined);
    setLocation(document.location || undefined);
    setWeather(document.weather || undefined);
    if (document.partiesPresent) setPartiesPresent(document.partiesPresent);
    setWorkInProgress(document.currentWorkInProgress || undefined);
    setAssociatedUsers(document.documentUserList?.map(({ userId }) => userId) || []);
    setAssociatedGroups(
      document.documentUserGroupList?.map(({ userGroupId }) => userGroupId) || [],
    );

    if (document.files && document.files.length > 0) {
      const documentImageFiles = [...document.files].filter(
        (file) =>
          !file.name.toLowerCase().endsWith('.pdf') && !file.name.toLowerCase().endsWith('.zip'),
      );
      setIsLoadingImages(true);

      try {
        const results = await Promise.allSettled(
          documentImageFiles.map(async (file) => ({
            file,
            image: await getImageByFileId(file.id, false),
          })),
        );
        const validResults = results.filter(
          (r) => r.status === 'fulfilled',
        ) as PromiseFulfilledResult<{ file: IFile; image: string }>[];

        setImages([...images, ...validResults.map((r) => r.value.image)]);
        setUploadedFiles(validResults.map((r) => r.value.file));
        setImageDescriptions([
          ...imageDescriptions,
          ...validResults.map((r) => r.value.file.description || ''),
        ]);
      } finally {
        setIsLoadingImages(false);
      }

      const documentFiles = document.files.filter(
        (f) => f.name.toLowerCase().endsWith('.pdf') || f.name.toLowerCase().endsWith('.zip'),
      );
      setExistingFiles(documentFiles);
    }

    setIsLoading(false);
  };

  const handleUploadProgress = (event: any) => {
    setUploadProgress(Math.round((100 * event.loaded) / event.total));
  };

  const isInvalid = () => {
    return !dateOfObservation || !title?.trim();
  };

  const handleDocumentUpdate = async (isDraft?: boolean) => {
    setIsSubmitting(true);
    dispatch(blockNavigation());
    const { projectId } = params;
    try {
      if (currentDocument?.id) {
        setSubmittingMessage('Updating document');
        const documentToUpdate: IRestrictedDocumentPatch = {
          isDraft: isDraft !== currentDocument.isDraft ? isDraft : undefined,
          dateOfObservation:
            dateOfObservation?.toISOString() !== currentDocument.dateOfObservation
              ? dateOfObservation?.format('YYYY-MM-DD')
              : undefined,

          title: title !== currentDocument.title ? title : undefined,
          description:
            description !== currentDocument.description ? description?.trim() : undefined,
          contactCompany: company !== currentDocument.contactCompany ? company : undefined,
          location: location !== currentDocument.location ? location : undefined,
          weather: weather !== currentDocument.weather ? weather : undefined,
          partiesPresent:
            partiesPresent !== currentDocument.partiesPresent ? partiesPresent : undefined,
          currentWorkInProgress:
            workInProgress !== currentDocument.currentWorkInProgress
              ? workInProgress?.trim()
              : undefined,
        };

        if (uploadedFiles.length > 0) {
          await Promise.all(
            uploadedFiles.map((file, i) =>
              updateFile(currentDocument.id, file.id, { description: imageDescriptions[i] }),
            ),
          );
        }

        const usersToAdd = associatedUsers.filter(
          (associatedUserId) =>
            !currentDocument.documentUserList?.some(
              (docUser) => associatedUserId === docUser.userId,
            ),
        );
        const usersToRemove =
          currentDocument.documentUserList
            ?.filter(
              (docUser) =>
                !associatedUsers.some((associatedUserId) => docUser.userId === associatedUserId),
            )
            ?.map((docUser) => docUser.userId) || [];
        const groupsToAdd = associatedGroups.filter(
          (id) =>
            !currentDocument.documentUserGroupList?.some(({ userGroupId }) => userGroupId === id),
        );
        const groupsToRemove =
          currentDocument.documentUserGroupList
            ?.filter(({ userGroupId }) => !associatedGroups.some((id) => id === userGroupId))
            .map(({ userGroupId }) => userGroupId) || [];

        await modifyDocumentById(currentDocument.id, {
          patch: documentToUpdate,
          followers: {
            addUserIds: usersToAdd,
            removeUserIds: usersToRemove,
            addUserGroupIds: groupsToAdd,
            removeUserGroupIds: groupsToRemove,
          },
        });

        for (let i = 0; i < files.length; i += 1) {
          const file = files[i];
          setSubmittingMessage(`Uploading ${file.name}`);
          await uploadFile(currentDocument.id, file);
          setUploadProgress(0);
        }

        dispatch(reloadDocument());
        dispatch(allowNavigation());
        history.push(`/main/projects/${projectId}/documents/field-reports/${currentDocument.id}`);
      } else {
        setSubmittingMessage('Creating document');
        const templates = await getTemplates();

        const documentToInsert: IInsertionDocumentWithGraph = {
          isDraft: true,
          documentTemplateId: templates[DocumentTemplateType.FieldReports],
          creatorUserId: user.id,
          lastUpdatedByUserId: user.id,
          dateOfObservation: dateOfObservation?.format('YYYY-MM-DD'),
          title,
          projectId,
        };

        const newDocument = await insertDocument(documentToInsert);

        dispatch(allowNavigation());
        history.push(`/main/projects/${projectId}/documents/field-reports/${newDocument.id}/edit`);
      }
    } catch (error: any) {
      console.error(`Error creating or updating field report: ${error.message}`);
    } finally {
      setIsSubmitting(false);
      dispatch(allowNavigation());
    }
  };

  useEffect(() => {
    if (params.documentId) {
      fetchCreatedDocument();
    }
  }, [params.documentId]);

  return (
    <main className={classes.content}>
      <NavAppbar />
      <Grid container justify="center">
        <Grid item xs={12}>
          <PageTitle title={`${params.documentId ? 'Edit' : 'Create'} Field Report`} />
        </Grid>
      </Grid>
      {isLoading ? (
        <CircularLoader />
      ) : (
        <Paper elevation={3} className={classes.paper}>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <form>
              <Grid container spacing={3} style={{ marginTop: 8, marginBottom: 8, maxWidth: 800 }}>
                <Grid item xs={12} md={6} lg={6} xl={6}>
                  <FormControl className={`${classes.formControl} ${classes.textFieldMaxWidth}`}>
                    <Typography className={classes.label}>Title</Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      className={classes.input}
                      placeholder="Title"
                      value={title}
                      onChange={(event) => setTitle(removeNewlines(event.target.value))}
                    />
                  </FormControl>
                  <FormControl className={`${classes.formControl} ${classes.textFieldMaxWidth}`}>
                    <Typography className={classes.label}>Date of Observation</Typography>
                    <KeyboardDatePicker
                      required
                      value={dateOfObservation}
                      onChange={(d) => setDateOfObservation(d)}
                      inputVariant="outlined"
                      className={classes.input}
                      format="MM/DD/YYYY"
                    />
                  </FormControl>
                  {currentDocument ? (
                    <FormControl className={`${classes.formControl} ${classes.textFieldMaxWidth}`}>
                      <Typography className={classes.label}>Company</Typography>
                      <TextField
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        placeholder="Company"
                        value={company}
                        onChange={(event) => setCompany(removeNewlines(event.target.value))}
                      />
                    </FormControl>
                  ) : null}
                  {!currentDocument ? (
                    <>
                      {!isSubmitting ? (
                        <SubmitButton
                          disabled={isInvalid()}
                          onClick={() => handleDocumentUpdate()}
                          fullWidth
                          style={{ maxWidth: 376 }}
                        >
                          Proceed With Creation
                        </SubmitButton>
                      ) : (
                        <CircularLoader />
                      )}
                    </>
                  ) : null}
                </Grid>
                {currentDocument ? (
                  <>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <FormControl
                        className={`${classes.formControl} ${classes.textFieldMaxWidth}`}
                      >
                        <Typography className={classes.label}>Location</Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Location"
                          className={classes.input}
                          value={location}
                          onChange={(event) => setLocation(removeNewlines(event.target.value))}
                        />
                      </FormControl>
                      <FormControl
                        className={`${classes.formControl} ${classes.textFieldMaxWidth}`}
                      >
                        <Typography className={classes.label}>Weather</Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Weather"
                          className={classes.input}
                          value={weather}
                          onChange={(event) => setWeather(removeNewlines(event.target.value))}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormControl
                        className={`${classes.formControl} ${classes.textFieldMaxWidth}`}
                      >
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.label}>Parties Present</Typography>
                          <Button
                            size="small"
                            color="primary"
                            onClick={() => setPartiesPresentDialogOpen(true)}
                            style={{ position: 'absolute', right: 0 }}
                            startIcon={<PersonAddRoundedIcon />}
                          >
                            Add party
                          </Button>
                        </Box>
                        {partiesPresent.map((party, i) => (
                          <FormControl
                            key={i}
                            className={`${classes.formControl} ${classes.textFieldMaxWidth}`}
                          >
                            {i > 0 && (
                              <Typography align="right">
                                <Link
                                  component="button"
                                  onClick={(e: React.SyntheticEvent) => {
                                    e.preventDefault();
                                    removeParty(i);
                                  }}
                                >
                                  Remove
                                </Link>
                              </Typography>
                            )}
                            <TextField
                              fullWidth
                              variant="outlined"
                              placeholder={`Party ${i + 1}`}
                              className={classes.input}
                              value={party}
                              onChange={(e) => {
                                const parties = [...partiesPresent];
                                parties[i] = e.target.value;
                                setPartiesPresent([...parties]);
                              }}
                            />
                          </FormControl>
                        ))}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormControl className={classes.formControl} style={{ maxWidth: 600 }}>
                        <Typography className={classes.label}>General Observation</Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="General Observation"
                          multiline
                          rows={8}
                          className={classes.input}
                          value={description}
                          onChange={(event) => setDescription(event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} xl={12}>
                      <FormControl className={classes.formControl} style={{ maxWidth: 600 }}>
                        <Typography className={classes.label}>Work in Progress</Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          placeholder="Work in Progress"
                          multiline
                          rows={8}
                          className={classes.input}
                          value={workInProgress}
                          onChange={(event) => setWorkInProgress(event.target.value)}
                        />
                      </FormControl>
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
          </MuiPickersUtilsProvider>
          {currentDocument ? (
            <>
              <Divider />

              {/* Notification list section */}
              <Box style={{ margin: '40px 0px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<NotificationsActiveRoundedIcon />}
                  onClick={() => setAssociationDialogOpen(true)}
                  style={{ marginBottom: 12 }}
                >
                  Define Notification List
                </Button>
                <InlineNotificationList
                  associatedUsers={associatedUsers}
                  associatedGroups={associatedGroups}
                />
              </Box>
              <Divider />

              {/* Image dropzone section */}
              <div style={{ padding: '40px 0px 24px' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<InsertDriveFileIcon />}
                  onClick={() => {
                    setFileUploadDialogOpen(true);
                  }}
                >
                  Select Documents
                </Button>
                <div style={{ marginTop: 8 }}>
                  <em>Only *.PDF and *.ZIP files will be accepted</em>
                </div>
                <div style={{ display: 'flex', width: '80%', marginTop: 8 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                    {files.length > 0 ? (
                      <Typography className={classes.label} style={{ marginBottom: 8 }}>
                        New Files
                      </Typography>
                    ) : null}
                    {files.map((file) => (
                      <div
                        key={file.lastModified}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginLeft: 8,
                          marginBottom: 4,
                        }}
                      >
                        {file.name.toLowerCase().endsWith('.pdf') ? (
                          <PDFIcon />
                        ) : (
                          <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
                        )}
                        <Typography style={{ marginLeft: 8 }}>{file.name}</Typography>
                        <IconButton
                          onClick={() => {
                            setFiles((prev) => prev.filter((f) => f.name !== file.name));
                          }}
                          style={{ padding: 0, marginLeft: 8 }}
                        >
                          <ClearIcon />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  {existingFiles.length > 0 && (
                    <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
                      <Typography className={classes.label} style={{ marginBottom: 8 }}>
                        Existing Files
                      </Typography>
                      {existingFiles.map((file) => (
                        <div
                          key={file.id}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 8,
                            marginBottom: 4,
                          }}
                        >
                          {fileIsPdf(file) ? (
                            <PDFIcon />
                          ) : (
                            <InsertDriveFileOutlined htmlColor="#7A797A" style={{ fontSize: 30 }} />
                          )}
                          <Typography style={{ marginLeft: 8 }}>{file.name}</Typography>
                          {canDeleteFile && (
                            <IconButton
                              onClick={() => deleteExistingDocumentFile(file)}
                              style={{ padding: 0, marginLeft: 8 }}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <Divider />
              {isDesktop || isSurface() ? (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<CameraAlt />}
                  onClick={() => setPhotoDialogOpen(true)}
                  style={{ marginTop: 8 }}
                >
                  Take Photo
                </Button>
              ) : null}
              {/* Image dropzone section */}
              <div {...getRootProps()} className={classes.dropzone}>
                <input {...getInputProps()} />
                <Button variant="outlined" color="primary" startIcon={<ImageIcon />}>
                  Select images
                </Button>
                <div style={{ marginTop: 8 }}>
                  <em>Only *.jpeg and *.png images will be accepted</em>
                </div>
              </div>

              {isUploadingImages ? (
                <Typography variant="body1" style={{ fontWeight: 500, marginBottom: 16 }}>
                  DO NOT NAVIGATE AWAY FROM THIS PAGE
                </Typography>
              ) : null}
              {(isUploadingImages || isLoadingImages) && (
                <CircularLoader style={{ marginBottom: 16 }} />
              )}

              <Grid
                container
                spacing={3}
                direction="column"
                style={{ paddingTop: '16px', maxWidth: 500 }}
              >
                {images.map((image, i) => (
                  <Grid key={i} item xs style={{ position: 'relative' }}>
                    <img
                      src={image?.toString()}
                      alt=""
                      style={{ borderRadius: 5, objectFit: 'cover', maxWidth: '96%' }}
                    />

                    <IconButton
                      onClick={() => deleteUploadedImageFile(i)}
                      style={{ position: 'absolute', padding: 0, marginLeft: 4 }}
                    >
                      <HighlightOff />
                    </IconButton>
                    <TextField
                      size="small"
                      variant="outlined"
                      placeholder="Description"
                      value={imageDescriptions[i]}
                      onChange={(e) => {
                        const descriptions = [...imageDescriptions];
                        descriptions[i] = e.target.value;
                        setImageDescriptions([...descriptions]);
                      }}
                      style={{ marginTop: 10, width: '96%' }}
                    />
                  </Grid>
                ))}
              </Grid>

              <Box display="flex" alignItems="center" justifyContent="flex-end" paddingTop={8}>
                {isSubmitting ? (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Typography style={{ fontWeight: 500 }}>{submittingMessage}</Typography>

                    {submittingMessage.includes('Uploading') ? (
                      <>
                        <Typography style={{ marginTop: 4, marginBottom: 4 }}>
                          {uploadProgress}%
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={uploadProgress}
                          style={{ width: '80%', height: 6 }}
                        />
                      </>
                    ) : (
                      <CircularLoader />
                    )}
                  </div>
                ) : (
                  <>
                    <Button
                      variant="outlined"
                      color="primary"
                      disabled={isInvalid()}
                      onClick={() => handleDocumentUpdate(true)}
                    >
                      Save draft
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={isInvalid()}
                      onClick={() => handleDocumentUpdate(false)}
                      style={{ marginLeft: 8 }}
                    >
                      Publish
                    </Button>
                  </>
                )}
              </Box>
            </>
          ) : null}
        </Paper>
      )}
      <Snackbar
        open={snackOpen}
        autoHideDuration={5000}
        onClose={() => setSnackOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackOpen(false)} severity="error">
          Failed to create files. Contact Centerline support if this issue persists. You will need
          to create another document.
        </Alert>
      </Snackbar>
      <ManagePermissionsDialog
        dialogOpen={associationDialogOpen}
        closeDialog={() => setAssociationDialogOpen(false)}
        type={ManagePermissionsDialogType.AssociatedUsers}
        associatedUsers={associatedUsers}
        associatedGroups={associatedGroups}
        setAssociatedUsers={setAssociatedUsers}
        setAssociatedGroups={setAssociatedGroups}
      />
      <FileUploadDialog
        open={fileUploadDialogOpen}
        newFiles={files}
        allowMultiple
        addFiles={(newFiles) => {
          setFiles([...files, ...(newFiles as File[])]);
        }}
        canSubmit={files.length > 0}
        removeFile={(file) => handleFileDelete(file as File)}
        disableComments
        handleClose={() => setFileUploadDialogOpen(false)}
        title="Upload File"
        disableDesignUpload
      />
      <SelectCompanyUsersDialog
        open={partiesPresentDialogOpen}
        handleClose={() => setPartiesPresentDialogOpen(false)}
        existingUserIds={[]}
        submit={(selectedUsers, selectedGroupIds, nonCenterlineUsers) =>
          addPartiesPresent(selectedUsers, nonCenterlineUsers)
        }
        type={ManagePermissionsDialogType.PartiesPresent}
      />
      {isDesktop || isSurface() ? (
        <TakePhotoDialog
          open={photoDialogOpen}
          close={() => setPhotoDialogOpen(false)}
          webcamRef={webcamRef}
          takePhoto={captureImage}
        />
      ) : null}
    </main>
  );
};

const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100vh',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
  },
  formControl: {
    width: '100%',
  },
  label: {
    fontWeight: 500,
  },
  input: {
    margin: '12px 0px 24px',
  },
  dropzone: {
    padding: '24px 0px 24px',
  },
  imageGrid: {
    position: 'relative',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  image: {
    maxWidth: '100%',
    objectFit: 'contain',
  },
  imageRemoveButton: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
  removeIcon: {
    color: '#949494',
  },
  textFieldMaxWidth: {
    maxWidth: 376,
  },
}));

export default AddFieldReportsPage;

/**
 * TODO:
 * Load images one at a time rather than displaying them all at once
 * Need a “created by” field - autofilled based on login?
 * autofilled based on construction setup entries?
 */
