import { TableCell, TableRow, Tooltip } from '@material-ui/core';
import { Message, Visibility } from '@material-ui/icons';
import React from 'react';
import { INumberedDocumentView, WorkflowStatusType } from '../../api-client/autogenerated';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import {
  ascendingComparator,
  descendingComparator,
} from '../../main-components/document-index/DocumentIndexUtils';
import { fileIsPdf, parseDate } from '../../scripts/utils';

const getUserFriendlyStatus = (status?: WorkflowStatusType | null) => {
  if (status === WorkflowStatusType.SubmittedForReview) return 'Submitted for Review';
  if (status === WorkflowStatusType.UnderReview) return 'Under Review';
  return 'Review Complete';
};

export default function getSubstitutionRequestRows(
  data: INumberedDocumentView[],
  handleClick?: (id?: string) => void,
) {
  return data
    .filter((doc) => doc.files?.length === 0 || doc.files?.every((f) => f.isVerified))
    .sort((a, b) => ascendingComparator(a, b, 'documentNumber'))
    .map((request) => {
      const dateElement = request.createdOn
        ? parseDate(request.createdOn).format('MM/DD/YYYY hh:mm')
        : 'N/A';
      const file =
        request.files && request.files.length > 0
          ? request.files.sort((a, b) => descendingComparator(a, b, 'createdOn'))[0]
          : undefined;
      return (
        <TableRow key={request.id}>
          <TableCell>{request.documentNumber}</TableCell>
          <TableCell>{dateElement}</TableCell>
          <TableCell>{request.contactName}</TableCell>
          <TableCell>{request.contactCompany}</TableCell>
          <TableCell>{request.description}</TableCell>
          <TableCell>{getUserFriendlyStatus(request.workflowStatus)}</TableCell>
          <TableCell>
            {file && fileIsPdf(file) && (
              <IconButton
                onClick={() => (handleClick ? handleClick(file.id) : null)}
                style={{ padding: 0 }}
              >
                <Visibility htmlColor="#000" style={{ marginLeft: 7 }} />
              </IconButton>
            )}
            {request.workflowStatus === WorkflowStatusType.Completed && request.reviewComment && (
              <Tooltip
                title={
                  <Typography style={{ textTransform: 'none' }}>{request.reviewComment}</Typography>
                }
                placement="top"
                arrow
              >
                <Message style={{ marginLeft: 8 }} />
              </Tooltip>
            )}
          </TableCell>
          <TableCell>{request.willPublishResponse ? 'Yes' : 'No'}</TableCell>
        </TableRow>
      );
    });
}
